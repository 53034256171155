<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW REQUEST</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-card-text>
          <v-row class="mt-5">
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="date_request"
                label="Request Date"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-text-field
                v-model="name"
                label="Request By"
                dense
                outlined
                readonly
              ></v-text-field>
              <v-select
                v-if="!is_already_req"
                dense
                outlined
                v-model="type_of_sector"
                :items="['Health','Education','Community','Livelihood','Youth','Environment','Protection','Meetings']"
                label="Type of Sector"
                required
                :rules="rules.combobox_rule"
              ></v-select>
              <v-text-field
                v-else
                v-model="type_of_sector"
                label="Type of Sector"
                dense
                outlined
                readonly
              ></v-text-field>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" v-if="!is_already_req">
                <v-btn
                  color="primary"
                  class="me-3 mt-4 w-full"
                  @click="create_request"
                  v-if="!saving "
                >
                  Create New Request
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              md="9"
              v-if="is_already_req"
            >
              <v-row>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-select
                    dense
                    outlined
                    v-model="particulars_id"
                    :items="list_of_request_particulars"
                    label="Particulars"
                    item-value="id"
                    item-text="particulars"
                    required
                    :rules="rules.combobox_rule"
                  ></v-select>
                  <v-text-field
                    v-model="date_of_implementation"
                    label="Date of Implementaion"
                    dense
                    outlined
                    type="date"
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                  <v-text-field
                    v-model="description"
                    label="Description / Name of Project"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="number"
                  ></v-text-field>

                  <!-- alert -->
                  <v-col cols="12" v-show="alert2">
                    <v-alert
                      color="warning"
                      text
                      class="mb-0"
                    >
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{alert_message2}}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="success"
                      class="me-3 mt-4 w-full"
                      @click="save_request_details"
                      v-if="!saving "
                    >
                      Save Details
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="success"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                    <v-btn
                      color="warning"
                      class="me-3 mt-4 w-full"
                      @click="done_request"
                      v-if="!saving2 "
                    >
                      Done Request
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="warning"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-col>
              </v-row>
              <h2>Request Data</h2>
              <v-data-table
                :headers="headers"
                :items="list_of_request_data"
                :loading="data_table_loading"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item="{ item }">
                  <tr
                  >
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_particulars.particulars }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td class="text-center">
                      <v-icon
                        class="mr-2"
                        color="error"
                        @click="delete_request_details(item)"
                      >
                        {{icons.mdiDelete}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline, mdiCloudUploadOutline, mdiAccountPlus
    , mdiCash
    , mdiDelete
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_already_req: true,
      saving: false,
      saving2: false,
      alert: false,
      alert_message: '',
      alert2: false,
      alert_message2: '',

      data_table_loading: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Particulars', value: 'members.cmf_no'},
        {text: 'Description', value: 'members.last_name'},
        {text: 'Amount', value: 'amount'},
        {text: 'Action', value: 'amount'},
      ],

      list_of_request_data: [],
      list_of_request_particulars: [],
      date_request: '',
      type_of_sector: '',
      lates_request: {},
      particulars_id: 0,
      description: '',
      date_of_implementation: '',
      amount: '0',
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      value: Object,
    },
    setup() {
      return {
        show: false,

        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
          mdiDelete,
        },
      }
    },
    mounted() {
      this.date_request = moment().format('MMMM DD, YYYY')
      this.not_yet_done_request_date()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name', 'user_id', 'month_start', 'month_end', 'name']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('request', ['register_new_request', 'get_not_yet_done_request', 'done_new_request']),
      ...mapActions('request_data', ['register_new_request_data', 'delete_request_data']),
      ...mapActions('system_data', ['change_snackbar']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      not_yet_done_request_date() {
        const data = new FormData()
        data.append('created_id', this.user_id);
        this.get_not_yet_done_request(data)
          .then(response => {
            this.lates_request = response.data[0].details
            this.list_of_request_particulars = response.data[0].active_particulars
            this.type_of_sector = this.lates_request!=null?this.lates_request.sector:''
            this.list_of_request_data = this.lates_request!=null?this.lates_request.request_data:[]
            this.is_already_req = this.lates_request!=null
          })
          .catch(error => {
            console.log(error)
          })
      },
      async create_request() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('created_id', this.user_id);
          data.append('district_id', this.district_id);
          data.append('sector', this.type_of_sector);
          this.register_new_request(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.not_yet_done_request_date()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              console.log(error)
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      async save_request_details() {
        this.saving = true
        this.alert2 = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('request_id', this.lates_request.id);
          data.append('particulars_id', this.particulars_id);
          data.append('date_of_implementation', this.date_of_implementation);
          data.append('description', this.description.toUpperCase());
          data.append('amount', this.amount);
          this.register_new_request_data(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
            })
            .catch(error => {
              this.alert2 = true
              this.alert_message2 = error
              console.log(error)
              this.saving = false
            })
          this.not_yet_done_request_date()
        } else {
          this.alert2 = true
          this.alert_message2 = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      async delete_request_details(item) {
        const data = new FormData()
        data.append('id', item.id);
        this.delete_request_data(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.not_yet_done_request_date()
      },
      async done_request() {
        const data = new FormData()
        data.append('id', this.lates_request.id);
        this.done_new_request(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving = false
          })
          .catch(error => {
            this.alert2 = true
            this.alert_message2 = error
            console.log(error)
            this.saving = false
          })
        this.not_yet_done_request_date()
      },
    }
  }
</script>
